/**
*   Global State Manager:
*   - only put things in here that are truly global (e.g. Cart & Customer Data, Theme)
*   otherwise make it a local state or pass it in a prop
*/


// use our initial state as a type of manifest for the Global State
export const initialState = {
    user: {
        isLoggedIn: false,
        email: "",
        customer_group_id: 5,
        exists: null,
        external_customer_id: 0,
        user_id: 0
    },
    cart: {},
    thirdParty: {
        zendesk: false,
        nostoSession: process.env.NODE_ENV === "development" ? "64d25a2e95be7b70ae573a09" : null
    },
    widget:{
        isOpen: false,
        component: null,
        showBackdrop: true
    }
}


export const GlobalReducer = (state, action) => {
    switch(action.type){

        case "setWidget":
            return{
                ...state,
                widget: {
                    ...state.widget,
                    ...action.data
                }
            }


        case "nostoSession":
            return{
                ...state,
                thirdParty:{
                    ...state.thirdParty,
                    nostoSession: action.data
                }
            }

        case "zendeskInit":
            return{
                ...state,
                thirdParty: {
                    ...state.thirdParty,
                    zendesk: action.data
                }
            }


            
        case "login":
            return{
                ...state,
                user: {
                    ...state.user,
                    isLoggedIn: true,
                    ...action.data
                }
            };

            

        case "setEmail":
            return{
                ...state,
                user: {
                    ...state.user,
                    ...action.data
                }
            };


            
        case "logout":
            return{
                ...state,
                user: {
                    ...state.user,
                    isLoggedIn: false,
                    data: {}
                }
            };
        

        default:
            throw new Error("Doh! You did not choose GlobalReducer method type to perform. Please try agin or go to GlobalContext.js for available types.")
    }
};
